const dataPointsCustomer = [
  {
    value: '1300+',
    title: 'OFFERINGS',
    body: 'from different vendors',
  },
  {
    value: '250+',
    title: 'VENDORS',
    body: 'offer their services',
  },
  {
    value: '>120',
    title: 'EVENTS',
    body: 'organized with us',
  },
  {
    value: '0%',
    title: 'COMISSION',
    body: 'only direct prices',
  },
];

const dataPointsBusiness = [
  {
    value: '3',
    title: 'Minutes',
    body: 'for registration on Eventmaker',
  },
  {
    value: '+2-6',
    title: 'new clients',
    body: 'in first month after registration',
  },
  {
    value: '50+',
    title: 'Categories',
    body: 'of products & services',
  },
  {
    value: '400+',
    title: 'Search requests',
    body: 'from clients each day',
  },
];

export const getAchievementsConfig = ({
  source,
}: {
  source: 'customer' | 'business';
}) => (source === 'customer' ? dataPointsCustomer : dataPointsBusiness);

import React from 'react';
import s_Customer from './Achievements_Customer.module.scss';
import s_Business from './Achievements_Business.module.scss';
import { ActionButton } from '../../ActionButton/ActionButton';
import { useLandingPageContext } from '../../../context';
import { getAchievementsConfig } from './config';

export const Achievements = () => {
  const { source } = useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const dataPoints = getAchievementsConfig({ source });

  return (
    <section className={s.SectionAchivements}>
      <h3 data-animation>
        Why <span>Eventmaker?</span>
      </h3>
      <div className={s.Achivements}>
        {dataPoints.map((data, index) => (
          <div key={index} data-animation className={s.Achivement}>
            <h5>{data.value}</h5>
            <p className={s.Achivement__title}>{data.title}</p>
            <p className={s.Achivement__body}>{data.body}</p>
          </div>
        ))}
      </div>

      {source === 'customer' ? (
        <ActionButton animation />
      ) : (
        <h3 data-animation>
          And we are <span>still growing...</span>
        </h3>
      )}
    </section>
  );
};

import { getStorageUrl } from '../../../Utils/getStorageUrl';

const configCustomer = [
  {
    img: getStorageUrl('landing/phone/customer_1.png'),
    title: (
      <>
        <span>Share</span> your preferences
      </>
    ),
    description:
      'You can look for suggested services based on the event type or narrow down your search for something specific.',
  },
  {
    img: getStorageUrl('landing/phone/customer_2.png'),
    title: (
      <>
        Dive into offerings to find the <span>perfect fit for your event</span>
      </>
    ),
    description:
      'Our system will recommend services that best match your criteria. Simply select the ones you prefer.',
  },
  {
    img: getStorageUrl('landing/phone/customer_3.png'),
    title: (
      <>
        <span>Discuss details</span> with the vendor
      </>
    ),
    description:
      'Enjoy direct communication, and confirm your booking for the preferred date if you are interested.',
  },
];

const configBusiness = [
  {
    img: getStorageUrl('landing/phone/business_1.png'),
    title: (
      <>
        <span>Register your business</span> on the platform
      </>
    ),
    description:
      'Briefly describe your business and provide the contact information.',
  },
  {
    img: getStorageUrl('landing/phone/business_2.png'),
    title: (
      <>
        <span>Share details</span> about your event services
      </>
    ),
    description:
      "We'll display them to potential clients whose search criteria align with your offerings.",
  },
  {
    img: getStorageUrl('landing/phone/business_3.png'),
    title: (
      <>
        <span>Clients will contact you </span> directly to discuss specifics and
        pricing
      </>
    ),
    description:
      'After client make a booking we will cahrge you 10% from total bill as our platform comission.',
  },
];

export const getIconPath = ({
  source,
  index,
}: {
  source: 'customer' | 'business';
  index: number;
}) =>
  `./svg/howItWorkIcon${index + 1}_${
    source.charAt(0).toUpperCase() + source.slice(1)
  }.svg`;

export const getHowItWorksConfig = ({
  source,
}: {
  source: 'customer' | 'business';
}) => (source === 'customer' ? configCustomer : configBusiness);

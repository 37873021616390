type Question = {
  id: number;
  question: string;
  answer: string;
};

const questionsCustomer: Question[] = [
  {
    id: 1,
    question: 'What is Eventmaker?',
    answer:
      'Marketplace where you can find the perfect services for your event by the best possible prices.',
  },
  {
    id: 2,
    question: 'Can you organize event for me?',
    answer:
      'Absolutely! If you prefer industry experts to plan your event, just share your requirements with us.',
  },
  {
    id: 3,
    question: 'How can you guarantee the best pricing?',
    answer: `"By booking with Eventmaker, each client gets the same pricing as booking directly with the Vendor." - It's our agreement with each business registered on the platform. \n\nAnd how do we make money? Businesses are happy to pay us a reasonable commission, because we bring them clients and reduce marketing costs.`,
  },
  {
    id: 4,
    question: 'Are you an event agency?',
    answer:
      "Nope, we're a startup that connects event industry. Our goal - to give you the most comfortable and modern event planning experience. However, if you prefer to work with event agencies, you can find their services in our catalog.",
  },
  {
    id: 5,
    question: 'Other questions',
    answer: 'Contact us.',
  },
];

const questionsBusiness: Question[] = [
  {
    id: 1,
    question: 'What is Eventmaker?',
    answer:
      'Eventmaker is a platform where event organizers find the perfect products and services for their events.',
  },
  {
    id: 2,
    question: 'How can I add my services to the platform?',
    answer:
      'Simply register your business and add photo and descriptions of your services. Clients will contact you if they would be interested in your offerings.',
  },
  {
    id: 3,
    question: 'How much does it cost?',
    answer:
      'You can add your offerings to the platform for free. When a client books any of your services through our platform, we charge a 10% fee based on the total bill.',
  },
  {
    id: 4,
    question: 'I represent an event agency, can I register?',
    answer:
      'Absolutely! Feel free to add your offerings to the platform. Many of our clients are looking for all-inclusive event planning services.',
  },
  {
    id: 5,
    question: 'Other questions',
    answer: 'Contact us.',
  },
];

export const getFAQConfig = ({ source }: { source: 'customer' | 'business' }) =>
  source === 'customer' ? questionsCustomer : questionsBusiness;

import React from 'react';
import s from './Benefits.module.scss';
import { useLandingPageContext } from '../../../context';
import { useMedia } from '../../../../../hooks/hook.media';
import clsx from 'clsx';

import sg from '../../../../../styles/global.module.scss';
import { TextLink } from '../../../../../components/TextLink/TextLink';
import { TextUsButton } from '../Form/Subforms/TextUsButton';
import { ActionButton } from '../../ActionButton/ActionButton';

export const Benefits = () => {
  // TODO: Use breakpoints
  const isDesktop = useMedia(['(min-width: 680px)'], [true, false], false);
  // console.log({ isDesktop });

  const { source } = useLandingPageContext();
  if (source !== 'customer') return null;

  return (
    <section className={s.section}>
      <div className={s.container}>
        <h3 data-animation className={s.section__title}>
          You can <span>use Eventmaker</span> platform <span>to book</span>...
        </h3>
        <div className={s.benefits}>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>1. Event services</h4>

            <p className={s.benefit__body}>
              All you need: venues, sound equipment, entertainment, and more.
              Only direct prices from businesses, with no commission.
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ About vendors and their services" />
                <TextLink title="▸ How our platform guarantee best prices?" />
                <ActionButton
                  style={{ marginTop: '20px' }}
                  text="Explore Catalog"
                />
              </div>
            </p>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>2. Event agencies</h4>

            <p className={s.benefit__body}>
              Find experts to plan your event: whether it's wedding, business
              conference or birthday party.
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ About event agencies' prices" />
                <TextLink title="▸ How to choose the right event agency?" />
                <ActionButton
                  style={{ marginTop: '20px' }}
                  text="Find Event Agency"
                />
              </div>
            </p>
          </div>
          <div data-animation className={s.benefit}>
            <h4 className={s.benefit__title}>3. Event concierge</h4>
            <p className={s.benefit__body}>
              Just share your vision, and we'll bring your event to life in the
              best possible way.
              <div
                className={clsx(sg.Stack_Vertical, sg.Gap_20)}
                style={{ marginTop: '30px' }}
              >
                <TextLink title="▸ Reviews" />
                <TextLink title="▸ How do we organize your events?" />
                <TextUsButton style={{ marginTop: '20px' }} />
              </div>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

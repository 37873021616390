import React from 'react';
import s_Customer from './About_Customer.module.scss';
import s_Business from './About_Business.module.scss';
import { AnchorEnum, buildAnchorId } from '../HeaderMobile/config';
import { productTypes } from './config';
import { useLandingPageContext } from '../../../context';
import { isPlatform } from '@ionic/core';
import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const About = () => {
  const { source, actionButtonShortName, onActionClick } =
    useLandingPageContext();

  const s = source === 'customer' ? s_Customer : s_Business;

  const nOrSpace = isPlatform('desktop') ? '\n' : ' ';

  return (
    <section className={s.section} id={buildAnchorId(AnchorEnum.ABOUT)}>
      <div className={s.sectionH1}>
        <div className={s.sectionH1__left}>
          <h1 data-animation style={{ whiteSpace: 'pre-wrap' }}>
            {source === 'customer'
              ? 'Planning your \nown event in Bali?'
              : 'Ready to collaborate?'}
          </h1>
          <p data-animation style={{ whiteSpace: 'pre-wrap' }}>
            {source === 'customer'
              ? 'Choose from 1,300+ event services offered by local businesses and individuals'
              : 'Every day clients in your city are searching for event services through EventMaker'}
          </p>
          <div data-animation className={s.sectionH1__btns}>
            <button className={s.choose}>
              <img
                alt=""
                src={
                  source === 'customer'
                    ? './svg/locationIcon_White.svg'
                    : './svg/locationIcon_Black.svg'
                }
              />
              Bali
              <img
                alt=""
                src={
                  source === 'customer'
                    ? './svg/arrowCity_White.svg'
                    : './svg/arrowCity_Black.svg'
                }
              />
            </button>

            <button className={s.explore} onClick={onActionClick}>
              {source === 'customer' && (
                <img alt="" src="./svg/searchIcon_Common.svg" />
              )}
              {actionButtonShortName}{' '}
              <img alt="" src="./svg/arrowAction_Common.svg" />
            </button>
          </div>
        </div>
        <div className={s.sectionH1__right}>
          <img
            data-animation
            className={
              source === 'customer' ? s.img__customer : s.img__business
            }
            src={
              source === 'customer'
                ? getStorageUrl('landing/phone/main_preview.png')
                : getStorageUrl('landing/ladys.webp')
            }
            alt=""
          />
        </div>
      </div>
      <div data-animation className={s.sectionH1__categories}>
        {productTypes.map((category, index) => (
          <div
            key={index}
            className={s.category}
            data-animation
            onClick={onActionClick}
          >
            <img
              alt={category.title}
              className={s.sectionH1__categoryImg}
              src={category.src}
            />
            <div className={s.category__bg} />
            <p className={s.category__title}>{category.title}</p>
            <div className={s.sectionH1__explore}>
              <p>{actionButtonShortName}</p>
              <img src="./svg/arrowBold_Common.svg" alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

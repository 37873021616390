import { getStorageUrl } from '../../../Utils/getStorageUrl';

export const productTypes = [
  {
    src: getStorageUrl('landing/product_categories/venue.webp'),
    title: 'Venues',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/sound.webp'),
    title: 'Sound Equipment',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/light.webp'),
    title: 'Lighting',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/artist.webp'),
    title: 'Performers',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/decor_landing.webp'),
    title: 'Decorations',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/agency.webp'),
    title: 'Event Agencies',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/entertainment_landing.webp'),
    title: 'Entertainment',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/food_landing.webp'),
    title: 'Catering',
    link: './',
  },
  {
    src: getStorageUrl('landing/product_categories/transport_landing.webp'),
    title: 'Transport',
    link: './',
  },

  {
    src: getStorageUrl('landing/product_categories/other_landing.webp'),
    title: 'Other',
    link: './',
  },
];

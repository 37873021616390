import { useForm } from 'react-hook-form';
import Form from '../../../../../../components/Form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationErrors } from '../../../../../../utils/validators';
import * as Yup from 'yup';
import { useSumbitFormCallback } from '../../../../../../components/Form/submit.hook';
import Input from '../../../../../../components/Inputs/Input/Input';
import InputArea from '../../../../../../components/Inputs/InputArea/InputArea';
import InputSelectOption from '../../../../../../components/Inputs/InputSelect/InputSelectOption';
import InputSelect from '../../../../../../components/Inputs/InputSelect/InputSelect';
import { TextUsButton } from './TextUsButton';
import clsx from 'clsx';
import s from '../Form.module.scss';

const eventTypes = [
  'Birthday',
  'Wedding',
  'Party',
  'Festival',
  'New Year',
  "Children's event",
  'Photo or video shoot',
  'Concert',
  'Business conference',
  'Exhibition',
  'Art show',
  'Yoga or dance',
  'Music session',
  'Sport event',
  'Other',
];

export type OrganizeEventFormValues = {
  type: string;
  date: string;
  text: string;
};

const validationOrdered = {
  type: Yup.string().required(validationErrors.REQUIRED),
  date: Yup.string().required(validationErrors.REQUIRED),
  text: Yup.string().required(validationErrors.REQUIRED),
};

const validationSchema: Yup.SchemaOf<Partial<OrganizeEventFormValues>> =
  Yup.object().shape(validationOrdered);

type FieldNameType = keyof typeof validationOrdered;

type Props = {
  initialFormValues: Partial<OrganizeEventFormValues>;
  submitCallback: (values: OrganizeEventFormValues) => Promise<void>;
  applyCustomStyles: boolean;
};

export const OrganizeEventForm: React.FC<Props> = ({
  initialFormValues,
  submitCallback,
  applyCustomStyles,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitted, isSubmitSuccessful, errors },
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialFormValues,
  });

  const { onSubmit } = useSumbitFormCallback<OrganizeEventFormValues>({
    submitCallback,
    handleSubmit,
    reset,
    trigger,
    setValue,
    isSubmitted,
  });

  return (
    <Form onSubmit={onSubmit} hidePrompt>
      <InputSelect
        label="Event type"
        className={applyCustomStyles ? s.IonInputSelect__custom : ''}
        labelClassName={applyCustomStyles ? s.IonInputLabel__custom : ''}
        _name={'type' satisfies FieldNameType}
        _register={register}
        _errorText={errors.type?.message}
      >
        {eventTypes.map((type, index) => (
          <InputSelectOption value={type} textPreview={type} key={index} />
        ))}
      </InputSelect>

      <Input
        label="Date"
        type="text"
        inputmode="text"
        className={applyCustomStyles ? s.IonInput__custom : ''}
        labelClassName={applyCustomStyles ? s.IonInputLabel__custom : ''}
        className__active={applyCustomStyles ? s.IonInput__custom__active : ''}
        _name={'date' satisfies FieldNameType}
        _register={register}
        _errorText={errors.date?.message}
      />

      <InputArea
        label="Requirements & ideas"
        minRows={5}
        labelClassName={
          applyCustomStyles
            ? clsx(s.IonInputLabel__custom, s.IonInputLabel__custom__margin)
            : undefined
        }
        className={clsx(applyCustomStyles ? s.IonTextarea__custom : undefined)}
        className__active={
          applyCustomStyles ? s.IonTextarea__custom__active : undefined
        }
        _name={'text' satisfies FieldNameType}
        _register={register}
        _errorText={errors.text?.message}
      />

      <TextUsButton
        style={{ marginTop: '25px' }}
        applyCustomStyles={applyCustomStyles}
      />
    </Form>
  );
};

import s from './TextLink.module.scss';

type Props = {
  title: string;
  routerLink?: string;
};

export const TextLink = ({ title }: Props) => {
  return <span className={s.TextLink}>{title}</span>;
};
